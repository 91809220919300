.loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-logo {
  width: 200px;
}

.loading-text {
  margin-top: 1rem;
  font-size: 3rem;
}
